import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react'
import { supabase } from './supabaseClient'
import Auth from './Auth'
import Account from './Account'
import List from './List';

export default function App() {
  const [session, setSession] = useState(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container" style={{ padding: '50px 0 100px 0' }}>
      <img src={logo} className="App-logo" alt="logo" />
      {!session ? <Auth /> : <> <Account key={session.user.id} session={session} /> <hr/> <List/> </>}
    </div>
  )
}