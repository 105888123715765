import { useEffect, useState } from 'react'
import { supabase } from './supabaseClient'

const resourceId = 'todos3'

export default function List() {
  const [loading, setLoading] = useState(false)
  const [todos, setTodos] = useState([])

  const [summary, setSummary] = useState('')
  const [desc, setDesc] = useState('')


  const getTodos = async () => {

    try {
      setLoading(true)
      const { data: todos, error } = await supabase.from(resourceId).select('*')
      setTodos(todos)
      if (error) throw error
      console.log('Check your email for the login link!')
    } catch (error) {
      console.log(error.error_description || error.message)
    } finally {
      setLoading(false)
    }
  }
  useEffect(()=> {
    getTodos()
  },[])

  const handleChange = (ev) => {
    setSummary(ev.currentTarget.value)
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    try {
      setLoading(true)
      const { data, error } = await supabase.from(resourceId).insert({summary, description: desc}).select()
      const newTodos = [...todos, data]
      // setTodos(todos)
      setTodos(newTodos)
      console.log(data)
      if (error) throw error
    } catch (error) {
      console.log(error.error_description || error.message)
    } finally {
      setLoading(false)
  }
}

  console.log("fsfsf", todos, summary, supabase)

  return (
    <div className="row flex-center flex">
      <div className="col-6 form-widget" aria-live="polite">
        <h2 className="header">List</h2>
        {loading ? (
          'geting todos...'
        ) : (
          <ul>
            {todos.map(item => <li key={item.id}>{item.summary} | {item.description}</li>)}
          </ul>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <input type="text" value={summary} onChange={handleChange}/>
        <input type="text" value={desc} onChange={ev => setDesc(ev.currentTarget.value)}/>
        <button type='submit' >zapisz</button>
      </form>
    </div>
  )
}